import React, { useEffect, useMemo, useState } from 'react'
import YearSelector from '../_library/YearSelector'
import { useDispatch, useSelector } from 'react-redux'
import AccountSelector from './AccountSelector'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { SwapHoriz } from '@mui/icons-material'
import Icon from '@mdi/react'
import { mdiAccountCash } from '@mdi/js'
import FluxCreateDialog from './FluxCreateDialog'
import ApproDialog from './ApproDialog'
import AccountTable from './AccountTable'
import TabbedPage from '../_library/TabbedPage'
import { ACCOUNT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { selectAccountId } from '../../store/actions/dataActions'
import { compact } from 'lodash'
import AllEntryTable from './AllEntryTable'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


export const AccountPage = () => {
  
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  const accountHooks = useFirestore(ACCOUNT_COLLECTION)
  const accounts = accountHooks.getDocs()
  const selectedAccountId = useSelector(state => state.data.selectedAccountId)
  const dispatch = useDispatch()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [openFluxDialog, setOpenFluxDialog] = useState(false)
  const [openApproDialog, setOpenApproDialog] = useState(false)
  
  useEffect(() => {
    accountHooks.listen({
      where: adminMode ? [] : [['ownerRef', '==', userHooks.getDocRef(profile.id)]],
      orderBy: adminMode ? [['name', 'asc']] : [],
    })
    return accountHooks.unsubscribe
  }, [adminMode, profile.id])
  
  useEffect(() => {
    if (accounts && (!selectedAccountId || !adminMode) && profile.accountRef) {
      dispatch(selectAccountId(profile.accountRef.id))
    }
  }, [accounts, selectedAccountId, profile.accountRef, adminMode])
  
  const tabs = useMemo(() => compact([
    {
      key: 'pilot',
      label: 'Compte pilote',
      route: 'pilot',
      headerContent: <>
        {adminMode && <AccountSelector />}
        <YearSelector />
      </>,
      content: <AccountTable />,
    },
    adminMode && {
      key: 'all',
      label: 'Toutes les entrées',
      route: 'all',
      headerContent: <YearSelector />,
      content: <AllEntryTable />,
    },
  ]), [adminMode])
  
  return (
    <>
      <TabbedPage tabs={tabs} containerSx={{ pb: '100px' }} />
      {adminMode && <SpeedDial
        ariaLabel='speedDial'
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onClick={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        FabProps={{ color: 'secondary' }}
        sx={{
          position: 'fixed',
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
          '& .MuiSpeedDialAction-staticTooltipLabel': {
            width: 200,
          },
        }}
      >
        <SpeedDialAction
          icon={<SwapHoriz />}
          tooltipTitle='Créer un flux'
          tooltipOpen
          onClick={() => setOpenFluxDialog(true)}
        />
        <SpeedDialAction
          icon={<Icon path={mdiAccountCash} size={1} />}
          tooltipTitle='Approvisionner un compte pilote'
          tooltipOpen
          onClick={() => setOpenApproDialog(true)}
        />
      </SpeedDial>}
      {openFluxDialog && <FluxCreateDialog open onClose={() => setOpenFluxDialog(false)} />}
      {openApproDialog && <ApproDialog open onClose={() => setOpenApproDialog(false)} />}
    </>
  )
}

export default AccountPage
