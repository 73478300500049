import React, { useState } from 'react'
import { Grid, Button, TextField, IconButton, InputAdornment, OutlinedInput, InputLabel, FormControl } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { Visibility, VisibilityOff } from '@mui/icons-material'


export const LoginForm = () => {
  
  const auth = useAuth()

  // STATES
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [forgottenPwd, setForgottenPwd] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const submit = e => {
    e.preventDefault()
    forgottenPwd
      ? auth.resetPasswordAction(email)
      : auth.signIn({ email, password })
  }

  return (
    <form onSubmit={submit} id='loginForm'>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            required
            onChange={e => setEmail(e.target.value)}
            name='email'
            type='email'
            value={email || ''}
            label='Email'
            sx={{
              width: { xs: '80%', sm: '60%' },
              borderRadius: .5,
            }}
          />
        </Grid>

        {!forgottenPwd && <Grid item xs={12}>
          <FormControl sx={{ width: { xs: '80%', sm: '60%' } }}>
            <InputLabel htmlFor='password'>Mot de passe</InputLabel>
            <OutlinedInput
              id='password'
              type={showPassword ? 'text' : 'password'}
              label='Mot de passe'
              onChange={e => setPassword(e.target.value)}
              value={password || ''}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label={showPassword ? 'hide' : 'show'}
                    onClick={() => setShowPassword(val => !val)}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>}

        <Button variant='text' onClick={() => setForgottenPwd(prev => !prev)} sx={{ width: '100%', fontSize: '.9rem' }}>
          {forgottenPwd ? 'Connexion' : 'Mot de passe oublié ?'}
        </Button>

        <Grid container direction={'column'} alignItems={'center'}>
          {forgottenPwd
            ? <Button type='submit' variant='contained' color='primary' disabled={!email} sx={{ my: 1, width: 'fit-content' }}>
              Recevoir le lien par email
            </Button>
            : <Button type='submit' variant='contained' color='primary' disabled={!email || !password} sx={{ my: 1, width: 'fit-content' }}>
              Connexion
            </Button>
          }
        </Grid>
      </Grid>
    </form>
  )
}

export default LoginForm
