import React, { useMemo } from 'react'
import LogbookTable from './LogbookTable'
import AircraftSelector from '../aircrafts/AircraftSelector'
import { useSelector } from 'react-redux'
import FlightLogPanel from './FlightLogPanel'
import AllFlightsTable from './AllFlightsTable'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'
import YearSelector from '../_library/YearSelector'
import UserSelect from '../_library/UserSelector'


const FlightPage = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)
  
  const tabs = useMemo(() => compact([
    {
      key: 'logbook',
      label: 'Carnet de vol',
      route: 'logbook',
      headerContent: adminMode && <UserSelect />,
      content: <LogbookTable />,
    },
    {
      key: 'flightlog',
      label: 'Carnets de route',
      route: 'flightlog',
      headerContent: <AircraftSelector />,
      content: <FlightLogPanel />,
    },
    adminMode && {
      key: 'all',
      label: 'Tous les vols',
      route: 'all',
      headerContent: <YearSelector />,
      content: <AllFlightsTable />,
    },
  ]), [adminMode])
  
  return <TabbedPage tabs={tabs} />
}

export default FlightPage
