import React, { useMemo } from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../_library/Loading'
import { selectAccountId } from '../../store/actions/dataActions'
import { useFirestore } from '../../hooks/useFirestore'
import { ACCOUNT_COLLECTION } from '../../_constants/globals'


const AccountSelector = () => {
  
  const accountHooks = useFirestore(ACCOUNT_COLLECTION)
  const dbAccounts = accountHooks.getDocs()
  const selectedAccountId = useSelector(state => state.data.selectedAccountId)
  const dispatch = useDispatch()
  
  const accounts = useMemo(() => dbAccounts?.filter(a => a.activated), [dbAccounts])
  
  if (!accounts) return (<Loading/>)
  else return (
    <Autocomplete
      options={accounts}
      getOptionLabel={option => option?.name || accounts?.find(a => a.id === option)?.name || ''}
      renderInput={params => <TextField variant='standard' label='Compte' sx={{ color: 'white' }} {...params} inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password', // disable autocomplete and autofill
      }} />}
      value={selectedAccountId || ''}
      onChange={(event, newValue) => dispatch(selectAccountId(newValue.id))}
      isOptionEqualToValue={(option, value) => option?.id === value}
      disableClearable
      sx={{ display: 'inline-flex', minWidth: '200px' }}
    />
  )
}

export default AccountSelector
